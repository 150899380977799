<template>
  <b-card-code title="Teslim Edilen Ürünleriniz" no-body>
    <b-card-body>
      <div class="d-flex justify-content-between  flex-wrap">
        <b-form-group
          label-align-sm="left"
          label-for="sortBySelect"
          class="mr-1 mb-md-1"
        >
          <b-input-group class="mr-5">
            <b-form-select
              id="sortBySelect"
              v-model="seciliYil"
              label-field="Yıl"
              :options="yillar"
            >
             
            </b-form-select>
            <b-form-select
              id="sortBySelect"
              v-model="seciliAy"
              label-field="Ay"
              :options="aylar"
            >
              <template #first>
                <option value="0">
                  Ay Seçin
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="seciliBirim"
              :disabled="seciliAy == 0"
              :options="birimler"
            >
            </b-form-select>
          </b-input-group>
        </b-form-group>
        <b-button variant="flat-primary" size="sm" @click="excell">
          <feather-icon icon="DownloadIcon" class="mr-50" />
          <span class="align-middle">Excel</span>
        </b-button>
      </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :items="items"
      :fields="fields"
    >
      <template #cell(goster)="data">
        <b-button
          :href="data.item.link"
          target="_blank"
          variant="outline-primary"
          class="btn-icon"
        >
          <feather-icon icon="SearchIcon" />
        </b-button>
      </template>
      <template #cell(durum)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
    </b-table>
    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <teslimatlar-toplam-urunler :value="items[items.length - 1]" />
    </b-card-body>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import excelExport from "@/views/Services/ExcelExport";
import TeslimatlarToplamUrunler from "@/views/Teslimatlar/TeslimatlarToplamUrunler";
import store from "@/store";

export default {
  components: {
    TeslimatlarToplamUrunler,
    BCardCode,
  },

  watch: {
    async seciliAy() {
      let fisler = await this.$http.post("MusteriTeslimatlarV1/", {
        Guid: this.veri.guid,
        Ay: this.seciliAy,
          Yil: this.seciliYil,
        Birim: this.seciliBirim.length == 0 ? 0 : this.seciliBirim,
      });
      this.items = fisler.data;
    },
    async seciliBirim() {
      let fisler = await this.$http.post("MusteriTeslimatlarV1/", {
        Guid: this.veri.guid,
        Ay: this.seciliAy,
         Yil: this.seciliYil,
        Birim: this.seciliBirim,
      });
      this.items = fisler.data;
    },
  },
  mounted() {
    this.$http.get("MusteriTeslimatlarV1/" + this.veri.guid).then((i) => {
      if (i.data)
        this.birimler = i.data.map((i) => ({
          value: i["id"],
          text: i["addressHeader"],
        }));
    });
    this.aylar = [
      { value: "1", text: "Ocak" },
      {
        value: "2",
        text: "Şubat",
      },
      {
        value: "3",
        text: "Mart",
      },
      { value: "4", text: "Nisan" },
      { value: "5", text: "Mayıs" },
      { value: "6", text: "Haziran" },
      {
        value: "7",
        text: "Temmuz",
      },
      { value: "8", text: "Ağustos" },
      { value: "9", text: "Eylül" },
      { value: "10", text: "Ekim" },
      {
        value: "11",
        text: "Kasım",
      },
      { value: "12", text: "Aralık" },
    ];
    this.yillar = [
      { value: "2021", text: "2021" },
      {
        value: "2022",
        text: "2022",
      },
    ];
  },
  data() {
    return {
      seciliAy: "0",
      seciliYil: "2022",
      seciliBirim: [],
      birimler: [],
      aylar: [],
      yillar: [],
      fields: [
        { key: "fisNo", label: "Fiş Numarası" },
        { key: "tarih", label: "Tarih" },
        { key: "birim", label: "Birim" },
        { key: "teslimEden", label: "Teslim Eden" },
        { key: "teslimAlan", label: "Teslim Alan" },
        { key: "aciklama", label: "Açıklama" },
        { key: "urun", label: "Ürün" },
        { key: "miktar", label: "Miktar" },
        { key: "durum", label: "Durum" },
        { key: "goster", label: "Göster" },
      ],
      items: [],
      status: [
        {
          true: "Silindi",
          false: "Aktif",
        },
        {
          false: "light-primary",
          true: "light-danger",
        },
      ],
    };
  },
  computed: {
    veri: {
      get() {
        return store.getters["loginStoreModule/getUserBilgileri"];
      },
    },
  },
  methods: {
    excell() {
      excelExport(
        this.items.filter((i) => i.durum === false),
        "Su Teslim Raporu",
        "Rapor"
      );
    },
  },
};
</script>
