import XLSX from "xlsx"
import { saveAs } from 'file-saver';
const excelExport = (data,title,subject) => {
    let wb = XLSX.utils.book_new();
    wb.Props = {
        Title: title,
        Subject: subject,
        Author: "Han Kurumsal Tedarik",
        CreatedDate: new Date()
    };
    wb.SheetNames.push("Rapor");
    let aoo = [];

    for (let i = 0; i < data.length; ++i) {
        aoo.push({
            Tarih: data[i].tarih,
            FişNumarası: data[i].fisNo,
            Birim: data[i].birim,
            TeslimEden: data[i].teslimEden,
            TeslimAlan: data[i].teslimAlan,
            Açıklama: data[i].aciklama,
            Ürünler: data[i].urun,
            Miktar: parseFloat(data[i].miktar),
        });
    }
    let ws = XLSX.utils.json_to_sheet(aoo);
    wb.Sheets["Rapor"] = ws;
    let wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'binary'
    });
    function s2ab(s) {

        let buf = new ArrayBuffer(s.length);
        let view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;

    }
    saveAs(new Blob([s2ab(wbout)], {
        type: "application/octet-stream"
    }), title + '.xlsx');
}
export default excelExport;