<template>
  <b-list-group v-if="value">
    <b-list-group-item v-for="item in value['toplamurunlers']" :key="item['urun']"
                       className="d-flex justify-content-between align-items-center">
      <span class="mr-1">{{ item["urun"] }}</span>
      <b-badge
          variant="primary"
          pill
          className="badge-round"
      >
        {{ item["miktar"] }} Adet
      </b-badge>
    </b-list-group-item>
  </b-list-group>
</template>
<script>


export default {
  components: {},
  props: {
    value: String | Number | Boolean | Object | Array,
  },
  watch: {
    selected(val) {
      this.$emit('input', val)
    },
  },
  data() {
    return {
    }
  }
}
</script>
